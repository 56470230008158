import React, { useContext } from 'react';
import Button from '../../Button';
import SelectCustom from '../../Select/SelectCustom';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';
import { parseToOptions } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import sortOptions from '../../Options/sortOptions';

export default function OrdersFilterBar(props) {
	const { filter, handleFilter, handleSearch, openFilter, handleFilterSelect } = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='filterBar'>
			<div className='field'>
				<h4 className='label'>Search for order</h4>
				<input
					className='textFieldInput'
					onChange={(e) => {
						handleSearch(e.target.value);
					}}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Status</h4>
				<SelectCustom
					htmlOptions={[
						filter?.multiStatus && !filter?.status && (
							<option disabled value={filter?.multiStatus}>
								{filter?.multiStatus}
							</option>
						),
						<option value='all'>All</option>,
						<option value='0'>Draft</option>,
						<option value='1'>Pending</option>,
						<option value='2'>Accepted</option>,
						<option value='3'>Processing</option>,
						<option value='4'>Completed</option>,
						<option value='5'>Dispatched</option>,
						<option value='6'>Delivered</option>,
						<option value='7'>Disputed</option>,
						<option value='9'>Rejected</option>,
					]}
					fieldName='status'
					callback={(e) => {
						handleFilter(e.value, 'status');
					}}
					placeholder={'Select...'}
					value={filter?.status || filter?.multiStatus || 'all'}
					width={300}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Due date</h4>
				<SelectCustom
					htmlOptions={sortOptions}
					placeholder={'Select...'}
					fieldName='sort'
					callback={handleFilterSelect}
					value={filter?.sort}
					width={300}
				/>
			</div>
			<div className='field disabledDiv'>
				<h4 className='label'>Buyer</h4>
				<SelectCustom
					options={[
						{ name: 'All', value: undefined },
						...parseToOptions(teamState?.currentTeam?.members, 'members'),
					]}
					placeholder={'Select...'}
					fieldName='buyer'
					callback={handleFilterSelect}
					value={filter?.buyer}
					width={300}
				/>
			</div>
			<Button
				text='All filters'
				size='md'
				style='secondary'
				iconRight={<ArrowNarrowRightIcon />}
				onClick={openFilter}
			/>
		</div>
	);
}
