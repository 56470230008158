import React from 'react';
import TabGroup from './Tabs/TabGroup';

export default function FilterTabs(props) {
	const { leftGroup, rightGroup, setSelectedTabs, selectedTabs } = props || {};

	return (
		<div className='tabRow'>
			{leftGroup && (
				<TabGroup
					selectedTab={selectedTabs?.left}
					tabs={leftGroup}
					setTab={(key) => {
						setSelectedTabs((tabs) => ({ ...tabs, left: key }));
					}}
				/>
			)}
			{rightGroup && (
				<TabGroup
					selectedTab={selectedTabs?.right}
					tabs={rightGroup}
					setTab={(key) => {
						setSelectedTabs((tabs) => ({ ...tabs, right: key }));
					}}
				/>
			)}
		</div>
	);
}
