import React from 'react';
import { ReactComponent as EmptyStateSuppliersSVG } from '../../assets/figma_design_doc/empty_state_cloud_suppliers.svg';
import { ReactComponent as EmptyStateFilesSVG } from '../../assets/figma_design_doc/empty_state_cloud_files.svg';
import { ReactComponent as EmptyStateBlankSVG } from '../../assets/figma_design_doc/empty_state_cloud_blank.svg';
import { ReactComponent as EmptyStateBoxSVG } from '../../assets/figma_design_doc/empty_state_box.svg';
import { ReactComponent as EmptyStateBackgroundCircleSVG } from '../../assets/figma_design_doc/empty_state_background_circle.svg';
import { PlusIcon } from '../SVGIcons/SVGIcons';
import Button from '../Button';

export default function EmptyState(props) {
	const { type, header, body, btn1, btn2, thin } = props || {};
	return (
		<div className={`emptyStateCont${thin ? ' thin' : ''}`}>
			{type === 'suppliers' && <EmptyStateSuppliersSVG />}
			{type === 'files' && <EmptyStateFilesSVG />}
			{type === 'packages' && (
				<div className='bigIcon'>
					<EmptyStateBackgroundCircleSVG />
					<EmptyStateBoxSVG />
					<div className='featuredIcon'>
						<PlusIcon />
					</div>
				</div>
			)}
			{!type && <EmptyStateBlankSVG />}
			<h4 className='emptyStateHeader'>{header}</h4>
			{body && <p>{body}</p>}
			{btn1 || btn2 ? (
				<div className='btnCont'>
					{btn1 && (
						<Button
							style='secondary'
							text={btn1.text}
							iconLeft={btn1.icon}
							onClick={(e) => {
								btn1.callback(e);
							}}
						/>
					)}
					{btn2 && (
						<Button
							style='primary'
							text={btn2.text}
							iconLeft={btn2.icon}
							onClick={(e) => {
								btn2.callback(e);
							}}
						/>
					)}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}
