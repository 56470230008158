import React, { useContext, useEffect, useState } from 'react';
import ManageAppForm from '../../components/Forms/ManageAppForm';
import useForm from '../../hooks/useForm';
import usePost from '../../hooks/usePost';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useModals from '../../hooks/useModals';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import useFetch from '../../hooks/useFetch';
import { useLocation, useNavigate, useParams } from 'react-router';
import { makeFirstCharUpper } from '../../helpers';
import Button from '../../components/Button';
import Axios from 'axios';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const PLACEHOLDER_REASONS = [
	{
		id: '73dca98a-868a-4153-92bc-1f1ea1d83612',
		colour: '#ACF0F2',
		deleted: false,
		exam: true,
		hygiene: false,
		position: 1,
		reason: 'Exam',
	},
	{
		id: '076daf7d-27dc-4fc8-af9c-f968fa055786',
		colour: '#8151A1',
		deleted: false,
		exam: false,
		hygiene: true,
		position: 2,
		reason: 'Scale & Polish',
	},
	{
		id: '0eb48af1-fa19-4993-be3c-168b96c067ca',
		colour: '#FCD715',
		deleted: false,
		exam: true,
		hygiene: true,
		position: 3,
		reason: 'Exam + Scale & Polish',
	},
	{
		id: '78d3a56c-a2fe-406f-afeb-0a5d86852e4d',
		colour: '#FF3B34',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 4,
		reason: 'Continuing Treatment',
	},
	{
		id: '7f8835b4-70c8-4358-9bfd-331f0ef7cd4f',
		colour: '#8151A1',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 5,
		reason: 'Emergency',
	},
	{
		id: '854a0fd8-541e-4dd4-8da2-498ba1b73437',
		colour: '#9CCB3B',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 6,
		reason: 'Review',
	},
	{
		id: '4271d26e-c261-4428-a1c9-7420b96137f8',
		colour: '#179AB2',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 7,
		reason: 'Other',
	},
	{
		id: '2970f2fc-f1d5-4231-b6bc-f505b7d07228',
		colour: '#FCD715',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 8,
		reason: 'New Patient Exam',
	},
	{
		id: '0c601306-a85e-4b0d-aaa4-85b51cdd0fc8',
		colour: '#E53481',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 9,
		reason: 'Broken Tooth',
	},
	{
		id: '1b69adce-d43c-43ba-b7bd-d20b97743545',
		colour: '#FF3B34',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 10,
		reason: 'Toothache',
	},
	{
		id: 'f0fb0191-f2e6-4e36-99b6-0a895d2634fe',
		colour: '#FF3B34',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 11,
		reason: 'Pain',
	},
	{
		id: '18ddc02f-8dca-4218-89f8-5c0c2f2c0115',
		colour: '#FCB215',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 12,
		reason: 'Lost Filling',
	},
	{
		id: '3ce65d3b-efe6-4052-a9af-e1e02fdc65ee',
		colour: '#25B0E6',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 13,
		reason: 'Lost Crown',
	},
	{
		id: '317f01ae-b7c3-4433-ad7e-0045152cd482',
		colour: '#E53481',
		deleted: false,
		exam: false,
		hygiene: false,
		position: 15,
		reason: 'bridge fitting',
	},
];

export default function TeamAppManage() {
	const { teamState, teamDispatch } = useContext(CurrentTeamContext);

	let filter = [
		'title',
		'first_name',
		'middle_name',
		'last_name',
		'date_of_birth',
		'email_address',
		'nhs_number',
		'ni_number',
		'address_line_1',
		'address_line_2',
		'address_line_3',
		'town',
		'county',
		'postcode',
		'home_phone',
		'mobile_phone',
		'work_phone',
	];

	let location = useLocation();
	let appName = new URLSearchParams(location.search).get('appName');
	let fetchSuccess = new URLSearchParams(location.search).get('fetchSuccess');

	let integrationUuid = teamState.currentTeam?.integrations ? teamState.currentTeam?.integrations[appName] : '';

	let navigate = useNavigate();
	const { modalsState, flashFadeModal, handleModal } = useModals();
	const { formData, handleFormInput, handleFormSelect, handleNestedFormInput, setFormData } = useForm(null, true);
	const { data, fetch } = useFetch(`${config.apiv1}/integration/integration.read/${integrationUuid}`);
	const { post } = usePost(
		`${config.apiv1}/integration/integration.update/${integrationUuid}`,
		handleUpdateRes,
		'',
		'PATCH'
	);

	const [appointmentReasons, setAppointmentReasons] = useState(
		process.env.REACT_APP_ENV !== 'production' ? PLACEHOLDER_REASONS : null
	);
	const [checkAll, setCheckAll] = useState(false);
	const [checkAllPatient, setCheckAllPatient] = useState(false);
	const [checkAllAppointment, setCheckAllAppointment] = useState(false);

	function handleUpdateRes(res) {
		if (res.ok) {
			flashFadeModal('Integration options updated successfully');
		} else {
			flashFadeModal('There was an error while updating integration');
		}
	}

	function toggleAllPatientCheck() {
		handleFormInput(!checkAllPatient, 'title');
		handleFormInput(!checkAllPatient, 'first_name');
		handleFormInput(!checkAllPatient, 'middle_name');
		handleFormInput(!checkAllPatient, 'last_name');
		handleFormInput(!checkAllPatient, 'date_of_birth');
		handleFormInput(!checkAllPatient, 'email_address');
		handleFormInput(!checkAllPatient, 'nhs_number');
		handleFormInput(!checkAllPatient, 'ni_number');
		handleFormInput(!checkAllPatient, 'address_line_1');
		handleFormInput(!checkAllPatient, 'address_line_2');
		handleFormInput(!checkAllPatient, 'address_line_3');
		handleFormInput(!checkAllPatient, 'town');
		handleFormInput(!checkAllPatient, 'county');
		handleFormInput(!checkAllPatient, 'postcode');
		handleFormInput(!checkAllPatient, 'home_phone');
		handleFormInput(!checkAllPatient, 'mobile_phone');
		handleFormInput(!checkAllPatient, 'work_phone');
		setCheckAllPatient(!checkAllPatient);
	}

	function toggleAllAppointmentCheck() {
		for (let i = 0; i < appointmentReasons?.length; i++) {
			handleFormInput(!checkAllAppointment, appointmentReasons[i]?.reason);
			setFormData((formData) => ({
				...formData,
				reasons: { ...formData.reasons, [appointmentReasons[i]?.reason]: !checkAllAppointment },
			}));
		}
		setCheckAllAppointment(!checkAllAppointment);
	}

	function uninstallApp() {
		handleModal('deleteModal', {
			open: true,
			header: 'Are you sure you want to uninstall this app?',
			callback: () => {
				uninstall();
			},
		});

		async function uninstall() {
			let res = await Axios({
				url: `${config.apiv1}/integration/integration.delete/${integrationUuid}`,
				method: 'DELETE',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
				},
			});
			if (res.data?.ok) {
				navigate('/manage/apps');
				teamDispatch({ type: 'RE-FETCH_TEAM' });
			} else {
				handleModal('deleteModal', {
					open: false,
				});
				flashFadeModal('There was an error uninstalling the app');
			}
		}
	}

	async function refreshToken() {
		const lastState = uuidv4();
		Cookies.set(`lastState`, lastState, { expires: 0.2 });

		//!TEMP
		Cookies.set(`savedSecret`, data.clientSecret, { expires: 1 });
		Cookies.set(`savedID`, data.clientID, { expires: 1 });
		//!TEMP

		let newUrl = new URL(
			`${config.dentallyAPI}/oauth/authorize?` +
				`redirect_uri=${config.host}/redirects/dentally&scope=appointment patient:read ` +
				`patient:update user:read&response_type=code`
		);
		newUrl.searchParams.set('state', lastState);

		//!TEMP
		if (data.clientID) {
			newUrl.searchParams.set('client_id', data.clientID);
		}
		//!TEMP

		window.location.href = newUrl.href;
	}

	async function fetchAppointmentReasons() {
		let res = await Axios({
			method: 'GET',
			url: `${config.dentallyAPI}/v1/appointment_reasons?deleted=false`,
			headers: {
				Authorization: `Bearer ${data?.token}`,
			},
		});
		return res?.data?.appointment_reasons?.length > 0 ? res?.data?.appointment_reasons : null;
	}

	useEffect(async () => {
		if (data?.settings && Object.keys(data?.settings)?.length > 0) {
			handleFormInput(data?.settings?.hintFormat, 'hintFormat');
			for (let field in data?.settings) {
				if (filter.includes(`{{${field}}}`) && data.settings[field]) {
					setCheckAllAppointment(true);
					setCheckAllPatient(true);
					return;
				}
			}
		}
		if (appName === 'dentally') {
			setAppointmentReasons((await fetchAppointmentReasons()) || appointmentReasons);
		}
	}, [data]);

	useEffect(() => {
		if (fetchSuccess === 'true') {
			flashFadeModal('New dentally token fetched successfully!');
		} else if (fetchSuccess === 'false') {
			flashFadeModal('Error when trying to fetch new token, please reinstall integration');
		}
	}, []);

	return (
		<div className='pageInnerCont white'>
			<div className='teamAppsCont'>
				<FadeOutModal
					show={modalsState.modalFading?.show}
					isFading={modalsState.modalFading?.isFading}
					message={modalsState.modalFading?.message}
				/>
				{modalsState.deleteModal?.open && (
					<>
						<ConfirmModal
							value={modalsState.deleteModal.value}
							header={modalsState.deleteModal.header}
							callback={modalsState.deleteModal.callback}
							handleModal={handleModal}
							modalType={'deleteModal'}
							zIndex={999}
						/>
					</>
				)}
				<div className='appManageCont'>
					{appName === 'dentally' && (
						<>
							<h2>{makeFirstCharUpper(appName)} Options</h2>
							<div className='marginBottom-20'>
								<Button
									text='Refresh token'
									size='sm'
									style='secondary'
									onClick={() => {
										refreshToken();
									}}
									minWidth={135}
								/>
							</div>
							<div className='marginBottom-20'>
								<Button
									text='Uninstall'
									size='sm'
									style='secondary'
									onClick={() => {
										uninstallApp();
									}}
									minWidth={135}
								/>
							</div>
							<ManageAppForm
								handleFormInput={handleFormInput}
								handleNestedFormInput={handleNestedFormInput}
								toggleAllPatientCheck={toggleAllPatientCheck}
								toggleAllAppointmentCheck={toggleAllAppointmentCheck}
								formData={formData}
								setFormData={setFormData}
								checkAllPatient={checkAllPatient}
								checkAllAppointment={checkAllAppointment}
								appointmentReasons={appointmentReasons}
								integration={data}
								suggestions={filter}
								appName={appName}
							/>
							<button
								className='btn3-primary marginTop-40'
								onClick={() => {
									post({ settings: formData });
								}}>
								Save changes
							</button>
						</>
					)}
					{appName === 'labmanager' && (
						<>
							<h2>{makeFirstCharUpper(appName)} Options</h2>
							<div className='marginBottom-20'>
								<Button
									text='Uninstall'
									size='sm'
									style='secondary'
									onClick={() => {
										uninstallApp();
									}}
									minWidth={135}
								/>
							</div>
							<ManageAppForm
								handleFormInput={handleFormInput}
								handleFormSelect={handleFormSelect}
								formData={formData}
								post={post}
								reFetchIntegration={fetch}
								integration={data}
								setFormData={setFormData}
								suggestions={filter}
								appName={appName}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
