import React, { useContext, useState } from 'react';
import Button from '../components/Button';
import TextInput from '../components/Inputs/TextInput';
import useFetch from '../hooks/useFetch';
import config from '../config';
import Axios from 'axios';
import { checkFileType, cloudServiceAttachmentLabels, cloudServices, defaultDocketTemp, getCurrDate } from '../helpers';
import { docketTemp } from '../templates/templates';
import { v4 as uuidv4 } from 'uuid';

export default function AddDocket() {
	const [orderUuid, setOrderUuid] = useState('');

	const { fetch } = useFetch();
	const CHUNK_SIZE = 100 * 1000;

	async function createImgUrls(files) {
		if (files.length > 0) {
			let imgs = [];
			for (let i = 0; i < files.length; i++) {
				if (checkFileType(files[i]?.filename?.split('.')[1]) === 1) {
					if (
						files[i].originalname?.includes('fdi-iso-3950') ||
						files[i].originalname?.includes('palmernotation') ||
						files[i].originalname?.includes('uns') ||
						files[i].originalname?.includes('QR_code')
					) {
						if (files[i]?.filename?.includes('file-dupe-')) {
							continue;
						}
						try {
							const res = await Axios({
								url: `${config.api}/file?path=${files[i].path}`,
								method: 'GET',
								responseType: 'blob',
							});

							if (res.data) {
								const url = window.URL.createObjectURL(new Blob([res.data]));
								let file = files[i];
								file.url = url;
								imgs.push(file);
							}
						} catch (err) {
							// if (err.response.status === 401) {
							// 	window.location.reload();
							// }
						}
					}
				}
			}
			return imgs;
		}
	}

	function parseDocketFields(docket) {
		let parsedFields = {};
		for (let i = 0; i < docket?.fields?.length; i++) {
			if (docket?.fields[i]?.data?.fieldName) {
				parsedFields[docket?.fields[i]?.data?.fieldName] = {
					fieldText: docket?.fields[i]?.data?.fieldText,
				};
			}
		}
		return parsedFields;
	}

	const uploadChunk = async ({ chunk, index, filename, chunksDirUuid, teamUuid }) => {
		const formData = new FormData();
		formData.append('teamUuid', teamUuid);
		formData.append('fileAttachmentType', 1);
		formData.append('chunkIndex', index);
		formData.append('filename', filename);
		formData.append('chunksDirUuid', chunksDirUuid);
		formData.append('chunk', chunk);

		await Axios.post(`${config.apiv1}/file/chunk.create`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	async function postFiles({ newFiles, orderUuid, ignoreHook, teamUuid, sellerTeamUuid }) {
		console.log(ignoreHook);
		let uploadedChunksTotal = 0;

		for (let i = 0; i < newFiles?.length; i++) {
			if (!newFiles[i]) return;

			const chunksDirUuid = uuidv4();
			const file = newFiles[i];
			const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
			const filename = file.name?.split('.')[0];
			const mimetype = file.name?.split('.')[1];

			for (let i = 0; i < totalChunks; i++) {
				const start = i * CHUNK_SIZE;
				const end = Math.min(start + CHUNK_SIZE, file.size);
				const chunk = file.slice(start, end);

				await uploadChunk({ chunk, index: i, filename, chunksDirUuid, teamUuid });
				uploadedChunksTotal += 1;
			}
			let res = await Axios.post(`${config.apiv1}/file/chunks.merge`, {
				filename,
				totalChunks,
				chunksDirUuid,
				mimetype,
				orderUuid,
				ignoreHook,
				fileAttachmentType: 1,
				teamUuid: teamUuid,
				dupeTeamUuid: sellerTeamUuid,
				originalname: file.name,
				size: file.size,
			});
		}
		return;
	}

	async function generateAndPostDocket() {
		let order = await fetch(`${config.apiv1}/order/order.read/${orderUuid}`);
		let buyerTeam = await fetch(`${config.apiv1}/team/team.read/${order.buyerTeamUuid}`);
		let orderExtFiles = await fetch(
			`${config.apiv1}/externalAttachment/externalAttachment.read/order/${orderUuid}`
		);
		let dockets = await fetch(`${config.apiv1}/docket/dockets.read/${order.sellerTeamUuid}`);
		if (dockets.length === 0) {
			dockets = [defaultDocketTemp(buyerTeam)];
		}

		for (let service of Object.values(cloudServices)) {
			for (let i = 0; i < orderExtFiles.length; i++) {
				if (orderExtFiles[i]?.serviceUuid === service.serviceUuid) {
					orderExtFiles[i].service = service;
				}
			}
		}

		for (let i = 0; i < orderExtFiles.length; i++) {
			orderExtFiles[i] = { ...orderExtFiles[i].fields, service: orderExtFiles[i].service };
		}

		dockets = dockets?.filter((docket) => docket.docketType === 1);
		let index = dockets.findIndex((docket) => docket.docketUuid === order?.customFields?.docketUuid);

		let orderFiles = order.files;
		let toothChart = [];
		let charts = ['fdi-iso-3950', 'palmernotation', 'uns'];
		if (order.files) {
			orderFiles = await createImgUrls(orderFiles);
			orderFiles.forEach((file) => {
				if (
					file.originalname.includes(charts[0]) ||
					file.originalname.includes(charts[1]) ||
					file.originalname.includes(charts[2])
				) {
					let changedFile = { ...file };
					changedFile.type = 'image/png';
					toothChart.push(changedFile);
					return;
				}
			});
		}

		order?.customFields?.docketName ? delete order.customFields.docketName : null;
		order?.customFields?.docketUuid ? delete order.customFields.docketUuid : null;
		order?.customFields?.integrationMaps ? delete order.customFields.integrationMaps : null;
		let docketPDF = await docketTemp(
			order.customFields && Object.keys(order.customFields)?.length !== 0 ? order.customFields : {},
			toothChart,
			buyerTeam?.teamInfo?.teamName,
			order,
			orderFiles,
			buyerTeam,
			order?.shippingAddress,
			order?.behalfOf.name,
			parseDocketFields(dockets[index]),
			false,
			orderExtFiles,
			true
		);

		docketPDF.name = `docket_${getCurrDate()}.pdf`;

		let res = await postFiles({
			newFiles: [docketPDF],
			orderUuid,
			teamUuid: buyerTeam?.teamUuid,
			sellerTeamUuid: order.sellerTeamUuid,
			ignoreHook: order.orderProcessingStatus === 0 ? true : false,
		});

		return res;
	}

	return (
		<div>
			<TextInput text='Order Uuid' callback={setOrderUuid} />
			<Button
				text='Add docket to order'
				onClick={() => {
					generateAndPostDocket();
				}}
			/>
		</div>
	);
}
