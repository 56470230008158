import React from 'react';
import OrderComments from './GridItems/OrderComments';
import OrderFiles from './GridItems/OrderFiles';
import OrderFinancial from './GridItems/OrderFinancial';
import OrderItems from './GridItems/OrderItems';
import OrderLMInfo from './GridItems/OrderLMInfo';
import OrderProgress from './GridItems/OrderProgress';
import OrderReferences from './GridItems/OrderReferences';
import OrderSuborders from './GridItems/OrderSuborders';
import OrderTracking from './GridItems/OrderTracking';
import OrderDtlyInfo from './GridItems/OrderDtlyInfo';
import OrderExternalAttachments from './GridItems/OrderExternalAttachments';
import GridItemSkeleton from './GridItemSkeleton';

export default function OrderGrid(props) {
	// if (process.env.REACT_APP_ENV === 'development-local') {
	// 	props.order.integrations = {
	// 		soe: {
	// 			dentally: {
	// 				appData: {
	// 					id: 719,
	// 					date_of_birth: '1939-12-18',
	// 					title: 'Mr',
	// 					first_name: 'Tom',
	// 					middle_name: 'Sammy',
	// 					appointments: [
	// 						{
	// 							id: 542845996,
	// 							appointment_cancellation_reason_id: null,
	// 							arrived_at: null,
	// 							booked_via_api: false,
	// 							cancelled_at: null,
	// 							completed_at: null,
	// 							confirmed_at: null,
	// 							created_at: '2024-09-18T10:17:22.882+01:00',
	// 							did_not_attend_at: null,
	// 							duration: 5,
	// 							finish_time: '2024-09-19T15:20:00.000+01:00',
	// 							import_id: null,
	// 							in_surgery_at: null,
	// 							notes: 'Test',
	// 							patient_id: 719,
	// 							patient_image_url:
	// 								'https://www.gravatar.com/avatar/3d2e9006b668091f06963d8396294e4e.jpg?&r=pg&d=identicon&s=190',
	// 							patient_name: 'Tom West',
	// 							payment_plan_id: 89664,
	// 							pending_at: '2024-09-18T10:17:22.883+01:00',
	// 							practitioner_id: 159135,
	// 							reason: 'Exam',
	// 							room_id: null,
	// 							start_time: '2024-09-19T15:15:00.000+01:00',
	// 							state: 'Pending',
	// 							treatment_description: null,
	// 							updated_at: '2024-09-18T10:17:22.882+01:00',
	// 							user_id: 1741857,
	// 							practitioner_site_id: 'fca1dcf9-f0ea-4974-8740-2d10a9395cfd',
	// 						},
	// 						{
	// 							id: 542845996,
	// 							appointment_cancellation_reason_id: null,
	// 							arrived_at: null,
	// 							booked_via_api: false,
	// 							cancelled_at: null,
	// 							completed_at: null,
	// 							confirmed_at: null,
	// 							created_at: '2024-09-18T10:17:22.882+01:00',
	// 							did_not_attend_at: null,
	// 							duration: 5,
	// 							finish_time: '2024-09-19T15:20:00.000+01:00',
	// 							import_id: null,
	// 							in_surgery_at: null,
	// 							notes: 'Test',
	// 							patient_id: 719,
	// 							patient_image_url:
	// 								'https://www.gravatar.com/avatar/3d2e9006b668091f06963d8396294e4e.jpg?&r=pg&d=identicon&s=190',
	// 							patient_name: 'Tom West',
	// 							payment_plan_id: 89664,
	// 							pending_at: '2024-09-18T10:17:22.883+01:00',
	// 							practitioner_id: 159135,
	// 							reason: 'Exam',
	// 							room_id: null,
	// 							start_time: '2024-09-19T15:15:00.000+01:00',
	// 							state: 'Pending',
	// 							treatment_description: null,
	// 							updated_at: '2024-09-18T10:17:22.882+01:00',
	// 							user_id: 1741857,
	// 							practitioner_site_id: 'fca1dcf9-f0ea-4974-8740-2d10a9395cfd',
	// 						},
	// 					],
	// 				},
	// 			},
	// 		},
	// 	};
	// }

	return (
		<div className='orderGridCont'>
			<OrderReferences props={props} />
			<OrderProgress props={props} />
			<OrderFinancial props={props} />
			<OrderItems props={props} />
			<OrderFiles props={props} />
			<OrderTracking props={props} />
			<OrderComments props={props} />
			<OrderSuborders props={props} />
			{props?.isLMMember && props.isSeller && <OrderLMInfo props={props} />}
			{props?.order?.integrations?.soe?.dentally && <OrderDtlyInfo props={props} />}
			<OrderExternalAttachments props={props} />
		</div>
	);
}
