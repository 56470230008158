import React from 'react';

export default function Badge({ theme, type, fill, message, title, reason, badgeIcon, tag }) {
	return (
		<div className={`badgeCont${theme ? ` ${theme}` : ''}${type ? ` ${type}` : ''}${fill ? ` fill` : ''}`}>
			<div className='badge'>{badgeIcon ? badgeIcon : ''}</div>
			<div className='col'>
				<h4 className='title'>{title}</h4>
				<h4 className='reason'>{reason}</h4>
				<p className='message'>{message}</p>
				<p className='tag' style={{ fontStyle: 'italic' }}>
					{tag}
				</p>
			</div>
		</div>
	);
}
