import React from 'react';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import { TopnavCloseIcon } from '../SVGIcons/SVGIcons';
import Button from '../Button';

export default function FlexModal(props) {
	const {
		setShow,
		updateLoading,
		setUpdateLoading,
		body,
		callback,
		closeBtnCallback,
		callbackData,
		modalName,
		zIndex,
		btn1,
		btn2,
		width,
		closeBtn,
	} = props || {};
	return (
		<>
			<div className='flexModal' style={{ zIndex: zIndex, width: width || '' }}>
				{closeBtn === true && (
					<TopnavCloseIcon
						iconClass='closeIcon'
						onClick={() => {
							setShow(modalName, false);
							closeBtnCallback ? closeBtnCallback() : null;
						}}
					/>
				)}
				{!updateLoading ? (
					<>
						{body}
						<div className='btnCont modal reverse marginTop-20'>
							{callback && btn1 && (
								<Button
									text={btn1 || 'Save'}
									style='primary'
									size='md'
									maxWidth={200}
									onClick={() => {
										callback(callbackData);
										setUpdateLoading ? setUpdateLoading(true) : null;
									}}
								/>
							)}
							{btn2 && (
								<Button
									text={btn2}
									size='md'
									style='secondary'
									maxWidth={200}
									onClick={() => {
										setShow(modalName, false);
									}}
								/>
							)}
						</div>
					</>
				) : (
					<>
						<Loading type='circle' />
					</>
				)}
			</div>
			<Overlay loadOverlay={true} showOverlay={true} zIndex={zIndex} />
		</>
	);
}
