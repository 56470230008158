import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../Button';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

export default function Error(props) {
	const { reset, error } = props || {};

	const { userDispatch } = useContext(CurrentUserContext);

	let location = useLocation();
	let message = new URLSearchParams(location.search).get('message');
	let login = new URLSearchParams(location.search).get('login');

	let navigate = useNavigate();

	console.error('Debug info: ', error.message);

	return (
		<div className='errorPageCont'>
			<h3>Oops! Something went wrong. Please try again or contact support.</h3>
			{login ? (
				<Button
					text='Go to login'
					style='primary'
					onClick={() => userDispatch({ type: 'LOGIN', payload: true })}
				/>
			) : (
				<div className='btnCont'>
					<Button
						onClick={() => {
							window.location.reload();
							reset();
						}}
						style='primary'
						size='md'
						text='Reload page'
						maxWidth={200}
						fill
					/>
					<Button
						onClick={() => {
							reset();
							navigate('/');
						}}
						style='secondary'
						size='md'
						text='Return to dashboard'
						maxWidth={200}
						fill
					/>
				</div>
			)}
		</div>
	);
}
