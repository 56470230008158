import React, { useState } from 'react';
import { dateConverter, integrationDataLabels } from '../../../helpers';

export default function OrderDtlyInfo(props) {
	const { order } = props?.props || {};
	let dentallyData = order?.integrations?.soe?.dentally?.appData;
	let dentallyLabels = integrationDataLabels('dentally');

	const [chosenTab, setChosenTab] = useState('patient');

	return (
		<div className='gridItemOrder dentally'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Dentally</h4>
			</div>
			<div className='orderDetailsTabs'>
				<div
					className={`tab${chosenTab === 'patient' ? ' selected' : ''}`}
					onClick={() => setChosenTab('patient')}>
					Patient
				</div>
				<div
					className={`tab${chosenTab === 'appointments' ? ' selected' : ''}`}
					onClick={() => setChosenTab('appointments')}>
					Appointments
				</div>
			</div>
			{chosenTab === 'patient' ? (
				<div className='colCont'>
					<div>
						{dentallyData &&
							Object.keys(dentallyData).map((key, index) => {
								if (dentallyLabels[key] && key !== 'appointments') {
									return (
										<div className='row' key={index}>
											<p className='text'>{dentallyLabels[key]}: </p>
											<div className='data bold'>{dentallyData[key]}</div>
										</div>
									);
								} else {
									return <React.Fragment key={index}></React.Fragment>;
								}
							})}
					</div>
				</div>
			) : (
				<></>
			)}
			{chosenTab === 'appointments' ? (
				<div className='colCont'>
					{dentallyData?.appointments &&
						dentallyData.appointments?.map((appointment, key) => {
							if (appointment) {
								return (
									<div
										className={`${
											key !== dentallyData?.appointments?.length - 1 ? 'borderBottom ' : ''
										}paddingBottom-10`}
										key={key}>
										<h4>{`${dateConverter(appointment.start_time)}`}</h4>
										{appointment.patient_name ? (
											<div className='row'>
												<p className='text'>Patient name: </p>
												<div className='data bold'>{appointment.patient_name}</div>
											</div>
										) : (
											<></>
										)}
										{appointment.reason ? (
											<div className='row'>
												<p className='text'>Reason: </p>
												<div className='data bold'>{appointment.reason}</div>
											</div>
										) : (
											<></>
										)}
										{appointment.dentist ? (
											<div className='row'>
												<p className='text'>Dentist: </p>
												<div className='data bold'>{appointment.dentist}</div>
											</div>
										) : (
											<></>
										)}
										{appointment.state ? (
											<div className='row'>
												<p className='text'>Status: </p>
												<div className='data bold'>{appointment.state}</div>
											</div>
										) : (
											<></>
										)}
										{appointment.start_time ? (
											<div className='row'>
												<p className='text'>Starts at: </p>
												<div className='data bold'>
													{dateConverter(appointment.start_time, 'hh:mm A')}
												</div>
											</div>
										) : (
											<></>
										)}
										{appointment.duration ? (
											<div className='row'>
												<p className='text'>Duration: </p>
												<div className='data bold'>{appointment.duration} minutes</div>
											</div>
										) : (
											<></>
										)}
										{appointment.note ? (
											<div className='row'>
												<p className='text'>Note: </p>
												<div className='data bold'>{appointment.note}</div>
											</div>
										) : (
											<></>
										)}
									</div>
								);
							} else {
								return <React.Fragment key={key}></React.Fragment>;
							}
						})}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}
